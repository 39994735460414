import {
  Box,
  Button,
  Center,
  chakra,
  Flex,
  GridItem,
  Heading,
  Input,
  SimpleGrid,
  useColorModeValue,
  VisuallyHidden,
} from '@chakra-ui/react'
import { GetServerSideProps } from 'next'
import { useLogin } from '../controllers/login.controller'
import { AuthService } from '../services/auth.service'
import { compose } from '../ssr/compose'
import { auth } from '../ssr/guards/auth.guard'
import { PublicRouteGuard } from '../ssr/guards/publicRoute.guard'

const AuthServiceInstance = new AuthService()

const Login = () => {
  const controller = useLogin(AuthServiceInstance)

  return (
    <Box maxW='lg' px={8} py={24} mx='auto'>
      <GridItem colSpan={{ base: 'auto', md: 4 }}>
        <Box mb={6} rounded='lg' shadow='xl'>
          <Center pb={0} color={useColorModeValue('gray.700', 'gray.600')}>
            <Heading fontSize='md'>Backoffice</Heading>
          </Center>
          <SimpleGrid
            columns={1}
            px={6}
            py={10}
            spacing={5}
            borderBottom='solid 1px'
            borderColor={useColorModeValue('gray.200', 'gray.700')}
          >
            <chakra.form
              onSubmit={controller.handleSubmit(controller.onSubmit)}
              display='flex'
              flexDir='column'
              className='space-y-4'
            >
              <Flex>
                <VisuallyHidden>Usuario</VisuallyHidden>
                <Input
                  mt={0}
                  type='text'
                  placeholder='Usuario'
                  {...controller.register('user')}
                />
              </Flex>
              <Flex>
                <VisuallyHidden>Password</VisuallyHidden>
                <Input
                  mt={0}
                  type='password'
                  placeholder='Contraseña'
                  {...controller.register('password')}
                />
              </Flex>
              <Button
                isLoading={controller.isLoading}
                isDisabled={Object.keys(controller.errors).length > 0}
                colorScheme='brand'
                w='full'
                py={2}
                type='submit'
              >
                Ingresar
              </Button>
            </chakra.form>
          </SimpleGrid>
        </Box>
      </GridItem>
    </Box>
  )
}

export const getServerSideProps: GetServerSideProps = compose(
  (ctx, pageProps, fn) => auth(ctx, pageProps, fn, AuthServiceInstance, true),
  PublicRouteGuard,
)

export default Login
