import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoginSchema } from '../schemas/login.schema'
import { ILogin } from '../interfaces/login.interface'
import { AuthService } from '../services/auth.service'
import { useToast } from '@chakra-ui/react'
import { useRouter } from 'next/dist/client/router'

export const useLogin = (authService: AuthService) => {
  const [isLoading, setIsLoading] = useState(false)

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues: <ILogin>{
      password: '',
      user: '',
    },
  })

  const toast = useToast()

  const router = useRouter()

  const onSubmit = (input: ILogin) => {
    setIsLoading(true)
    authService
      .login(input)
      .then((res) => {
        router.push('/')
      })
      .catch((err) => {
        setIsLoading(false)
        toast({
          description: 'Credenciales erroneas',
          status: 'error',
        })
      })
  }

  return {
    register,
    errors,
    onSubmit,
    handleSubmit,
    isLoading,
  }
}
