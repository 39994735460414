import axios from 'axios'
import { API_URL } from '../config/config.exporter'

axios.defaults.withCredentials = true

console.log('API_URL', API_URL)

const instance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
})

export default instance
