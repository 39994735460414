import { ILogin } from '../interfaces/login.interface'
import _axios from '../utils/_axios'

export class AuthService {
  async login(input: ILogin): Promise<any> {
    const response = await _axios.post('/v1/account/office/login', input)
    return response.data
  }

  async me(cookie?: string) {
    const response = await _axios.get('/v1/account/office/me', {
      headers: {
        cookie,
      },
    })
    return response.data
  }

  async logout(cookie?: string) {
    const response = await _axios.get('/v1/account/office/logout', {
      headers: {
        cookie,
      },
    })
    return response.data
  }
}
